const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

export const useFetchToken = () => {
    const fetchToken = async (params: any): Promise<any> => {
        const headers: HeadersInit = {
            'Content-Type': 'application/json',
        };

        const convertParams = {
            ...params,
            user_id: params.user_id || params.userId || "",
            client_id: params.client_id || params.clientId || "",
            app_name: params.app_name || params.appName || ""

        }

        const response = await fetch(`${API_BASE_URL}/api/v1/users/external/auth`, {
            method: 'POST',
            headers: headers,
            body: JSON.stringify(convertParams)
        });

        if (!response.ok) {
            throw new Error(`Error: ${response.status} ${response.statusText}`);
        }

        const data = await response.json();
        return data;
    };

    return { fetchToken };
};
