import React, { useState, useEffect, useRef, useCallback } from 'react';
import { Box, Typography, IconButton, Button, CircularProgress, useMediaQuery, Alert, useTheme } from '@mui/material';
import Close from '@mui/icons-material/Close';
import VolumeOffRoundedIcon from '@mui/icons-material/VolumeOffRounded';
import VolumeUpRoundedIcon from '@mui/icons-material/VolumeUpRounded';
import SmartToyOutlinedIcon from '@mui/icons-material/SmartToyOutlined';
import HistoryOutlinedIcon from '@mui/icons-material/HistoryOutlined';
import TypingIndicator from './TypingIndicator';
import { queryClient } from '../utils/http';
import { useInfiniteQuery, useMutation } from '@tanstack/react-query';
import { v4 as uuidv4 } from 'uuid';
import InputBar from './InputBar';
import MessageBubble from './MessageBubble';
import { useConversations } from '../hook/useConversations';
import LanguageSelector from './LanguageSelector';
import VeraVoiceAnimation from './VeraVoiceAnimation';

interface Message {
    from_user: boolean;
    id: string;
    message: string;
    sender_id: string;
}

interface ConversationChatProps {
    conversation: any;
    handleCloseChat?: () => void;
    selectNewConversationCreated: (conv?: any) => void;
    hideInputBar?: boolean;
}

const initMessages = {
    id: uuidv4(),
    message: 'Hello, I am Vera. How can I assist you today?',
    sender_id: '-1',
    from_user: false
}

const ConversationChat: React.FC<ConversationChatProps> = ({ conversation: conversationProps, selectNewConversationCreated, handleCloseChat, hideInputBar }) => {
    const theme = useTheme();
    const [conversationId, setConversationId] = useState<string>(conversationProps?.id);
    const [currentConversation, setCurrentConversation] = useState<any>();
    const [messages, setMessages] = useState<Message[]>([]);
    const [isInputDisabled, setIsInputDisabled] = useState(false);
    const [isLoading, setLoading] = useState(false);
    const [showHistoryButton, setShowHistoryButton] = useState(false);
    const [errorSystem, setErrorSystem] = useState<string | null>(null);
    const [notAllowContinueConversation, setNotAllowContinueConversation] = useState<boolean | undefined>(false);
    const [isTyping, setIsTyping] = useState<boolean>(false);
    const containerRef = useRef<HTMLDivElement>(null);
    const inputRef = useRef<HTMLInputElement>(null);
    const conversationIdRef = useRef<string | null>();
    const conversationNewIdRef = useRef<string | null>();
    const firstFetchNewConversation = useRef<boolean>(false);
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));
    const [language, setLanguage] = useState<string>('en-US');
    const [isVeraSpeech, setVeraSpeech] = useState<boolean>(false);
    const [isSoundMode, setSoundMode] = useState<boolean>(false);

    const { createConversation, fetchConversationMessages, sendConversationMessages } = useConversations()

    const fetchMessages = async ({ pageParam }: { pageParam?: string }) => {
        const response = await fetchConversationMessages({
            conversation_id: conversationId,
            page_size: 20,
            last_message_id: firstFetchNewConversation.current ? undefined : pageParam,
            signal: new AbortController().signal
        });
        if (firstFetchNewConversation.current) firstFetchNewConversation.current = false
        const shouldShowHistoryButton = conversationId && (!response.page_size || pageParam === undefined);
        setShowHistoryButton(!!shouldShowHistoryButton);

        return response;
    };

    const {
        data,
        error,
        fetchPreviousPage,
        isFetching,
        isFetchingPreviousPage,
    } = useInfiniteQuery({
        queryKey: ['conversation-messages', conversationId],
        queryFn: fetchMessages,
        initialPageParam: undefined,
        getNextPageParam: () => undefined,
        getPreviousPageParam: (firstPage) => firstPage?.data?.[0]?.id ?? undefined,
        enabled: !!conversationId
    })

    const { mutate } = useMutation({
        mutationFn: async ({ conversation_id, message }: { conversation_id: null | string; message: string }) => {
            const { responseStream, status } = await sendConversationMessages({ conversation_id, message });
            if (status === 202) {
                await queryClient.invalidateQueries({ queryKey: ['conversations'] })
            }

            if (!conversationProps?.id) {
                selectNewConversationCreated({id: conversationNewIdRef.current})
            }

            if (status === 500) {
                setErrorSystem('Something error!')
                setIsTyping(false);
                setIsInputDisabled(false);
                setTimeout(() => {
                    if (inputRef.current) {
                        inputRef.current.focus();
                    }
                }, 100)
                return
            }
            if (status === 201) {
                setNotAllowContinueConversation(true)
                await queryClient.invalidateQueries({ queryKey: ['conversations'] })
            }

            const reader = responseStream.getReader();
            const decoder = new TextDecoder("utf-8");
            let done = false;
            let responseText = "";

            while (!done) {
                const { value, done: readerDone } = await reader.read();
                done = readerDone;

                if (value) {
                    responseText += decoder.decode(value, { stream: true });
                    setMessages((prevMessages) => {

                        const lastMessage = prevMessages[0];
                        if (lastMessage?.sender_id === '-1') {
                            prevMessages.shift()
                            return [
                                {
                                    ...lastMessage,
                                    message: responseText,
                                    sender_id: '-1',
                                    from_user: false
                                },
                                ...prevMessages,
                            ];
                        } else {
                            return [
                                {
                                    id: uuidv4(),
                                    message: responseText,
                                    sender_id: '-1',
                                    from_user: false
                                },
                                ...prevMessages,
                            ];
                        }
                    });
                    scrollToBottom(100)

                }
            }

            setIsTyping(false);
            setIsInputDisabled(false);
            setTimeout(() => {
                if (inputRef.current) {
                    inputRef.current.focus();
                }
            }, 100)
        },
        onError: (error) => {
            console.error('Error during message sending:', error);
            setIsTyping(false);
        },
        onMutate: () => {
            setIsTyping(true);
        },
    });

    const createNewConversations = useMutation({
        mutationFn: async ({ reference_conversation_id, name }: { reference_conversation_id?: string, name?: string }) => {
            const response = await createConversation({ name, reference_conversation_id });
            return response;
        },
        onError: (error) => {
            console.error("Mutation error:", error);
            // Handle mutation error, like showing an error message
        },
        onSuccess: () => {
            // Handle success if needed
        }
    });

    const createNewConversationFromThisConversation = useCallback(async ({ reference_conversation_id, name }: { reference_conversation_id?: string; name?: string; }) => {
        setLoading(true)
        try {
            const response: any = await createNewConversations.mutateAsync({ reference_conversation_id, name });
            if (response?.id) {
                await queryClient.invalidateQueries({ queryKey: ['conversations'] });
                selectNewConversationCreated({ id: response.id, reference_conversation_id: response.reference_conversation_id });  // Assuming this is a function to handle the selection
            }
        } catch (error) {
            console.error("Error creating new conversation:", error);
            // You can add error handling here, such as showing a notification or updating state
        } finally {
            setLoading(false)
        }
    }, [createNewConversations, queryClient, selectNewConversationCreated]);


    const handleSendMessage = async (input: string) => {
        setErrorSystem(null)
        let currentConversationId = conversationProps?.id
        if (!currentConversationId) {     
            const response: any = await createNewConversations.mutateAsync({ name: '' });
            if (response.id) {
                conversationNewIdRef.current = response.id
                currentConversationId = response.id
                await queryClient.invalidateQueries({ queryKey: ['conversations'] })
            }
        }

        if (input.trim()) {
            const userMessage: Message = {
                id: uuidv4(),
                message: input,
                sender_id: '1',
                from_user: true
            };

            setMessages((prevMessages) => [userMessage, ...prevMessages]);
            setIsInputDisabled(true);
            setIsTyping(true);
            mutate({ conversation_id: currentConversationId, message: input });
            scrollToBottom(100); // Ensure this is called after sending the message
        }
    };

    const handleShowHistory = () => {
        firstFetchNewConversation.current = true
        conversationIdRef.current = currentConversation?.reference_conversation_id
        setConversationId(currentConversation?.reference_conversation_id)
    }

    const scrollToBottom = (timeOut: number) => {
        const container = containerRef.current;
        if (container) {
            setTimeout(() => {
                container.scrollTop = container.scrollHeight;
            }, timeOut)
        }
    }

    useEffect(() => {
        firstFetchNewConversation.current = true
        setCurrentConversation(conversationProps)
        setConversationId(conversationProps?.id)
        setNotAllowContinueConversation(conversationProps?.ticket_flag)

        scrollToBottom(500)
        return () => {
            queryClient.setQueryData(['conversation-messages', conversationId], {
                pages: [],
                pageParams: [],
            });
        }
    }, [conversationProps?.id])

    useEffect(() => {
        if (currentConversation?.id) {

        }
    }, [currentConversation?.id])

    const getInfo = async () => {
        const token = localStorage.getItem("authToken");
        const main_url = process.env.REACT_APP_API_BASE_URL;

        try {
            const headers: HeadersInit = {
                'Content-Type': 'application/json',

            };

            // Conditionally add token to headers if it's not null
            if (token) {
                headers['Authorization'] = token;
            }

            const response = await fetch(`${main_url}/api/v1/kb_conversations/${conversationId}`, {
                method: 'GET',
                headers: headers,
            });
            const data = await response.json()
            console.log("response get conversation info", data)

            if (response.status === 200) {
                return data.data
                console.log("Get conversation info out successfully");
            } else {
                console.error("conversation failed with status:", response.status);
            }
        } catch (e) {
            console.log("Error during get conversation:", e);
        } finally {

        }
    }


    useEffect(() => {
        if (!conversationId) {
            queryClient.setQueryData(['conversation-messages', conversationId], {
                pages: [{
                    data: [initMessages],
                    page: 1
                }],
                pageParams: [],
            });
        }

        const fetchConversationInfo = async () => {
            if (conversationId) {
                const info = await getInfo();
                setCurrentConversation(info);
            }
        };


        fetchConversationInfo();

    }, [conversationId])

    useEffect(() => {
        if (data) {
            const container = containerRef.current;
            if (container) {
                const fetchedMessages = [...data.pages.flatMap(page => page.data)];
                if (conversationIdRef.current === conversationId) {
                    setMessages((prevMessages) => {
                        const updatedMessages = [...prevMessages, ...fetchedMessages.reverse(),];
                        return Array.from(new Set(updatedMessages.map(msg => msg.id))).map(id =>
                            updatedMessages.find(msg => msg.id === id)
                        );
                    });
                } else {
                    setMessages(fetchedMessages.reverse())

                }
            }
            conversationIdRef.current = conversationId
        }
    }, [data]);

    const handleScrolll = (event: React.UIEvent<HTMLDivElement>) => {
        const top = event.currentTarget.scrollHeight <= event.currentTarget.clientHeight - event.currentTarget.scrollTop + 100;

        if (top && !isFetchingPreviousPage) {
            fetchPreviousPage();
        }
    };

    let content;

    if (isFetching) {
        content = <Box sx={{ flex: 1, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <CircularProgress size={40} color={'inherit'} />
        </Box>
    }

    if (error) {
        content = <Typography variant='h4'>{error?.message}</Typography>
    }

    if (messages) {
        content = <Box ref={containerRef} onScroll={handleScrolll} sx={{ position: 'relative', display: "flex", flexDirection: 'column-reverse', flex: 1, p: 2, overflowY: 'auto', pb: 6 }}>
            {isTyping && <TypingIndicator />}

            {errorSystem && (
                <Box sx={{ width: '30%', alignSelf: 'center' }}>
                    <Alert severity="error" onClose={() => setErrorSystem(null)}>
                        {errorSystem}
                    </Alert>
                </Box>
            )}
            {messages.map((message: Message) => (
                <MessageBubble
                    key={message.id}
                    message={message.message}
                    language={language}
                    isSentByUser={message.from_user}
                />
            ))}
            {currentConversation?.reference_conversation_id && showHistoryButton ? isFetching ? <CircularProgress sx={{ alignSelf: 'center' }} size={24} color={'inherit'} /> : <Button
                variant="text"
                color="inherit"
                size="small"
                onClick={() => handleShowHistory()}
                sx={{ alignSelf: 'center', mt: 1, p: 1, fontSize: 12, textTransform: 'none', textDecorationLine: 'underline' }}>
                See previous conversation
                <HistoryOutlinedIcon sx={{ ml: 1, }} fontSize='small' color="inherit" />

            </Button> : null}
        </Box>
    }

    const changeSoundMode = () => {
        if (isSoundMode) {
            stopSpeech()
        }

        setSoundMode(!isSoundMode)
    }

    const stopSpeech = (): void => {
        if ('speechSynthesis' in window) {
          speechSynthesis.cancel(); // Force stop any ongoing speech
          setVeraSpeech(false)
        }
      };

    const VoiceAnimationZone = () => {
        if (!isSoundMode || !isVeraSpeech) return null
        return <Box sx={{ position: 'absolute', bottom: 70, alignSelf: 'center' }}>
            <VeraVoiceAnimation />
        </Box>

    }

    const SoundMode = () => {
        return (
            <Box sx={{ background: 'white', paddingRight: '8px', position: 'absolute', bottom: 70, alignSelf: 'flex-end', right: 0, boxShadow: 3, borderBottomLeftRadius: 100, borderTopLeftRadius: 100 }}>
                <IconButton aria-label="clear-input" onClick={changeSoundMode}>
                    {isSoundMode ? <VolumeUpRoundedIcon fontSize='medium' color='primary' /> : <VolumeOffRoundedIcon fontSize='medium' color='primary' />}
                </IconButton>
            </Box>
        )
    }

    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
            <Box sx={{
                color: theme.palette.text.primary,
                p: 1,
                display: 'flex',
                alignItems: 'center',
                justifyContent: isMobile ? 'center' : 'space-between',
                flexDirection: "row",
                boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
            }}>
                <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                    <Box sx={{ display: "flex", width: 30, height: 30, borderRadius: 30, alignItems: "center", justifyContent: "center" }}>
                        {/* <SmartToyOutlinedIcon fontSize='medium' sx={{ color: "#fff" }} /> */}
                        <img src="/vera.png" alt="Vera Icon" style={{ width: "100%", height: "100%", borderRadius: "50%" }} />
                    </Box>
                    <Box sx={{ ml: 2 }}>
                        <Typography sx={{ fontWeight: 600 }} variant="h5">Vera</Typography>
                        <Box sx={{
                            display: 'flex',
                            flexDirection: "row",
                            justifyContent: "center",
                            alignItems: "center"
                        }}>
                            <Box sx={{ mr: 1, height: 8, width: 8, borderRadius: 8, background: '#4CAF50' }} />
                            <Typography variant="h6">Online</Typography>

                        </Box>


                    </Box>
                    <Box sx={{ position: 'absolute', right: 40 }}>
                        <LanguageSelector language={language} setLanguage={setLanguage} />
                    </Box>
                </Box>

                {handleCloseChat ? <Box>
                    <IconButton sx={{ alignSelf: 'flex-end' }} aria-label="clear-input" onClick={handleCloseChat}>
                        <Close sx={{ fontSize: 14, color: '#000' }} />
                    </IconButton>

                </Box> : null}
            </Box>
            {content}
            <CreateNewConversationButton isVisible={!!notAllowContinueConversation} onClick={() => createNewConversationFromThisConversation({ reference_conversation_id: conversationProps?.id })} />
            {hideInputBar ? null : <Box sx={{ mx: 1, mb: 1 }}>
                <InputBar inputRef={inputRef} notAllowContinueConversation={notAllowContinueConversation} conversationId={conversationId} onSendMessage={handleSendMessage} disabled={isInputDisabled || notAllowContinueConversation} />
            </Box>}
            {isLoading && (
                <Box
                    sx={{
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        right: 0,
                        bottom: 0,
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        backgroundColor: 'rgba(255, 255, 255, 0.8)', // Semi-transparent background
                        zIndex: 1000, // High z-index to overlay other elements
                    }}
                >
                    <CircularProgress size={24} color="inherit" />
                </Box>
            )}
        </Box>
    );
};

const CreateNewConversationButton = React.memo(({ isVisible, onClick }: { isVisible: boolean, onClick: () => void }) => {
    if (!isVisible) return null;

    return (
        <Button
            variant="outlined"
            color="info"
            size="large"
            onClick={onClick}
            sx={{ mt: 1, mb: 1, fontSize: 12, textTransform: 'none', alignSelf: 'center' }}
        >
            Create new conversation from this conversation
        </Button>
    );
});


export default ConversationChat;
