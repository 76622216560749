import React from 'react';
import { Box, IconButton } from '@mui/material';
import VolumeUpRoundedIcon from '@mui/icons-material/VolumeUpRounded';
import VolumeMuteRoundedIcon from '@mui/icons-material/VolumeMuteRounded';
import useSpeechSynthesis from '../hook/useSpeechSynthesis';

const VoiceText: React.FC<{ text: string, language: string }> = ({ text, language }) => {
    const { synthesizeSpeech, stopSpeech, isSpeaking, setIsSpeaking } = useSpeechSynthesis()

    const handleClickVoulume = () => {
        if (!isSpeaking) {
            setIsSpeaking(isSpeaking)
            synthesizeSpeech(text, language)
        } else {
            stopSpeech()
        }
    }

    return (
        <Box sx={{ display: 'flex', background: 'white', width: 20, height: 20, alignItems: 'center', justifyContent: 'center', boxShadow: 2, borderRadius: 20 }}>
            <IconButton aria-label="volume" onClick={handleClickVoulume}>
                {isSpeaking ? <VolumeUpRoundedIcon fontSize='medium' color='primary' /> : <VolumeMuteRoundedIcon fontSize='medium' color='action' />}
            </IconButton>
        </Box>
    )
};

export default VoiceText;
