import { Box, Typography, Avatar } from '@mui/material';

const UserInfo: React.FC<{ user: any | null }> = ({ user }) => (
    user ? (
        <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', p: 2 }}>
            <Avatar alt="John Doe" src="" sx={{ width: 32, height: 32, mr: 2 }} />
            <Typography>{user.name}</Typography>
        </Box>
    ) : null
);


export default UserInfo;