import { Select, MenuItem, FormControl, SelectChangeEvent } from '@mui/material';

interface LanguageSelectorProps {
  language: string;
  setLanguage: (value: string) => void;
}

const LanguageSelector: React.FC<LanguageSelectorProps> = ({ language, setLanguage }) => {
  const handleChange = (event: SelectChangeEvent<string>) => {
    setLanguage(event.target.value as string);
  };

  return (
    <FormControl className="mb-4 w-2" sx={{ alignSelf: 'flex-end' }}>
      <Select
        labelId="language-select-label"
        id="language-select"
        value={language}
        onChange={handleChange}
        label="Language"
        sx={{
          '.MuiOutlinedInput-notchedOutline': {
            border: 'none', // Remove border here
          },
          '.MuiSelect-select': {
            padding: '2px', // Change padding here
          },
        }}
      >
        <MenuItem value="en-US">English (US)</MenuItem>
        <MenuItem value="fr-FR">French</MenuItem>
      </Select>
    </FormControl>
  );
};

export default LanguageSelector;
