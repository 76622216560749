// src/components/LoadingSpinner.tsx
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { CircularProgress, Box, Typography } from '@mui/material';
import { useFetchToken } from '../../hook/useFetchToken';
import { useAuth } from '../../context/AuthContext';

const Loading: React.FC = () => {
    const navigate = useNavigate();

    const [params, setParams] = useState<any>(undefined)

    const [loading, setLoading] = useState<boolean>(true)
    const [error, setError] = useState<string | null>(null)

    const { fetchToken } = useFetchToken();
    const { setUser, setToken } = useAuth();
    const getQueryParams = () => {
        const urlParams = new URLSearchParams(window.location.search);
        const params: { [key: string]: string | null } = {};

        // Iterate over each entry in URLSearchParams and store it in an object
        urlParams.forEach((value, key) => {
            params[key] = value;
        });

        return params;
    };

    useEffect(() => {
        const queryParams = getQueryParams();
        setParams(queryParams);

    }, []);
    // Fetch token when userId is available
    useEffect(() => {
        const fetchData = async () => {
            if (params !== undefined) {
                try {
                    const result = await fetchToken(params);

                    if (result.error_code === 0) {
                        // Extract token and user data from the response
                        const { access_token, ...user } = result.data;
                        // Set both token and user in context
                        setToken(access_token); // Setting the token
                        setUser(user); // Setting the rest of the user data (name, role, etc.)
                        navigate('/chatbox')
                    } else {
                        setError(result.message)
                        console.error('Login failed:', result.message);
                    }
                } catch (error) {
                    setError('Something wrong!')
                    console.error('Error fetching token:', error);
                } finally {
                    setLoading(false)
                }
            }
        };

        fetchData(); // Call the async function

    }, [params]);

    useEffect(() => {

        const queryParams = getQueryParams();
        // Event listener for messages from the WebView
        const handleMessage = (event: MessageEvent) => {
            try {
                const data = JSON.parse(event.data);
                setParams((prevParams: any) => ({
                    ...prevParams,
                    ...queryParams, // Keep existing query parameters
                    ...data, // Override/add data coming from WebView message
                  }));
            } catch (error) {
                console.error('Error parsing message data', error);
            }
        };
        window.addEventListener('message', handleMessage);
        return () => {
            window.removeEventListener('message', handleMessage);
        };
    }, []);

    let content;

    if (loading) {
        content = <CircularProgress color='inherit' />
    }

    if (error) {
        content = <Typography variant='h4'>{error}</Typography>
    }
    return (
        <Box
            sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: '100vh', // Full viewport height
                backgroundColor: 'background.paper', // Optional background color
            }}
        >
            {content}
        </Box>
    );
};

export default Loading;
