import React from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import { useTheme } from '@mui/material';
import { styled } from '@mui/system';
import ChatBox from './pages/chatbox';
import Loading from './pages/loading';

const App: React.FC = () => {
  const theme = useTheme()
  return (
    <Router>
      <div className='app' style={{
        backgroundColor: theme.palette.background.default,
      }}>
        <div className='content'>
          <Routes>
            <Route path="/" element={<Navigate to="/home" />} />
            <Route path="/home" element={<Loading />} />
            <Route path="/chatbox" element={<ChatBox />} />
          </Routes>
        </div>
      </div>
    </Router>

  );
};

export default App;
