import { Language } from '@mui/icons-material';
import AWS from 'aws-sdk';
import { useRef, useState } from 'react';

const useSpeechSynthesis = () => {
  // Use a ref to store the audio element for control
  const audioRef = useRef<HTMLAudioElement | null>(null);
  const [isSpeaking, setIsSpeaking] = useState(false); // State to track speaking status

  const synthesizeSpeech = async (text: string, language: string): Promise<void> => {
    const polly = new AWS.Polly();

    let voiceId = 'Joanna'

    if (language == 'en-US') {
      voiceId = 'Joanna'
    } else if (language == 'fr-FR') {
      voiceId = 'Celine'
    }

    const params: AWS.Polly.SynthesizeSpeechInput = {
      OutputFormat: 'mp3',
      Text: text,
      VoiceId: voiceId,
      LanguageCode: language // Choose any Polly voice here
    };

    try {
      const result: AWS.Polly.SynthesizeSpeechOutput = await polly.synthesizeSpeech(params).promise();

      // Handle the AudioStream output correctly
      if (result.AudioStream instanceof Uint8Array) {
        // If AudioStream is a Uint8Array (binary data)
        const audioBlob = new Blob([result.AudioStream], { type: 'audio/mp3' });
        const audioUrl = URL.createObjectURL(audioBlob);

        // Stop any previous audio if it is playing
        if (audioRef.current) {
          audioRef.current.pause();
        }

        // Play the new synthesized audio
        audioRef.current = new Audio(audioUrl);
        console.log("language", language)
        audioRef.current.play();
        
        setIsSpeaking(true);

        // Listen for the end of the speech and turn off volume or update state
        audioRef.current.onended = () => {
          setIsSpeaking(false);
        };
      } else {
        console.error('AudioStream is not in a supported format.');
      }
    } catch (error) {
      console.error('Polly TTS Error:', error);
    }
  };

  const stopSpeech = () => {
    if (audioRef.current) {
      audioRef.current.pause();
      audioRef.current.currentTime = 0;
      setIsSpeaking(false);
    }
  };

  return { synthesizeSpeech, stopSpeech, isSpeaking, setIsSpeaking };
};

export default useSpeechSynthesis;
